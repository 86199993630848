@media (min-width:556px) {
    .pad {
      padding-right: 100px !important;
      padding-left: 100px !important;
  }

  }

img.barcode {
    min-width: 100px;
}
.wputih {
    background-color: white;
    padding: 10px 10px 10px 20px;
}
.wl {
    width: 150px;
}
.wb {
    min-width: 200px;
    text-align: center;
    display: contents;
}
body.dark .pr {
    font-size: x-large !important;
    color: white;
}
body .pr {
    font-size: x-large !important;
    color: black;
}
.visamaster {
    
    width: 30% !important;
    padding: 10px !important;

}
.vm {
    text-align: right;
}

.padcard{
    padding-top: 2px;
    padding-bottom: 2px;
}
.chip {
    display: block;
    width: 23% !important;
    padding: 20px !important;
    opacity: 0.7;
}


.bblue {
    background-image: linear-gradient(#3880ff36, rgba(218, 216, 247, 0.5)), url(/src/corner.png);
    background-size: cover;
}

.byellow {
    background-image: linear-gradient(#ffd43842, rgba(247, 222, 216, 0.5)), url(/src/corner.png);
    background-size: cover;
}

.bpink {
    background-image: linear-gradient(#ff383836, rgba(235, 216, 247, 0.5)), url(/src/corner.png);
    background-size: cover;
}

.bgreen {
    background-image: linear-gradient(#7bff3836, rgba(216, 238, 247, 0.5)), url(/src/corner.png);
    background-size: cover;
}


*{
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    }