

  
  .larger {
    font-size: 120px;
    text-align: center;
    padding: 20px;
    color: white;
  }
  .rate {
    font-size: xxx-large;
    text-align: center;
    color: white;
    font-weight: bold;
  }
  
  .atasbawahr {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .atasbawah {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  .tengah {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .red {
    background: linear-gradient(to right, #7b4397, #dc2430);
  }
  
  .green {
    background: linear-gradient(to right, #136a8a, #267871);
  }
  
  .rainbow {
    background: linear-gradient(to left, #12c2e9, #c471ed, #f64f59);
  }
  
  .martini {
    background: linear-gradient(to left, #dce35b, #45b649);
  }
  
  .flare {
    background: linear-gradient(to left, #f12711, #f5af19);
  }
  
  .blue {
    background: linear-gradient(to left, #9cecfb, #65c7f7, #0052d4);
  }
  
  .pink {
    background: linear-gradient(to left, #bc4e9c, #f80759);
  }
  .cek{
    padding-top: 50px;
    padding-bottom: 10px;
    background: linear-gradient(to right, #138b2f, #267871);
  }
  .button-ios {
    border-radius: 4px;
    height: 2.8em;
    font-size: 1.2rem;
    color: #fff;
    background-color: #138b2f;
    margin: .4rem .2rem;
    padding: 0 1em;
  }
  .purple {
    background: linear-gradient(to left, #da22ff, #9733ee);
  }
  .purple2 {
    background: linear-gradient(to left, #9733ee, #f80759);
  }
  .biru {
    background: linear-gradient(to left, #4776e6, #8e54e9);
  }
  .hijau {
    background: linear-gradient(to left, #add100, #7b920a);
  }
  .rainbowblue{
    background: linear-gradient(to left, #00f260, #0575e6);
  }
  .pc {
    font-size: 18px;
    color: white;
  }
  .pcp {
    padding: 10px;
  }
  p {
    font-size: 16px;
  }
  
